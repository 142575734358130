import React from 'react'
//import { navigate } from 'gatsby-link'
import Helmet from 'react-helmet'
import Layout from '../../components/Layout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Link} from 'gatsby'

export default class ShoppingFeeds extends React.Component {
  constructor(props) {
    super(props)
    this.state = {  }
  }

  render() {
    return (
      <Layout>
        <Helmet>
            <title>WesData | Feed Optimization</title>
        </Helmet>
        <section className="section" >
            <div className="container">
                <div className="content">
                    <div className="columns">
                        <div className="column is-12">
                            <h1>Feed Optimization</h1>
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column is-3">
                            <div class="card">
                                <div class="card-image" style={{backgroundColor:'#406eb7',textAlign:'center',paddingTop:'1em',paddingBottom:'1em'}}>
                                    <FontAwesomeIcon icon="check-circle" size="7x" color="#fff"/>
                                </div>
                                <div class="card-content">
                                    <div class="media">
                                        <div class="media-content">
                                            <p class="title is-4">Feeds That Work</p>
                                        </div>
                                    </div>

                                    <div class="content">
                                    Optimize your shopping feeds for maximum exposure and ROAS.
                                    Take advantage of supported attributes per channel and ensure that your
                                    catalog doesn't carry dead weight.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="column is-6">
                            <div class="card"><div class="card-content">
                            <div className="columns">
                                <div className="column is-2" style={{textAlign:'center'}}>
                                    <FontAwesomeIcon icon="check-square" size="2x" color="#406eb7"/>
                                </div>
                                <div className="column is-10" style={{textAlign:'left'}}>
                                    <h3>Does Your Catalog Make the Grade?</h3>
                                    <p>Sending well formatted feeds is just one piece of the puzzle. In order to get the maximum ROAS from your campaigns,
                                        you need to weed-out the under-performers. Our feed optimization service helps you to do exactly that.</p>
                                </div>
                            </div>
                            <div className="columns">
                                <div className="column is-2" style={{textAlign:'center'}}>
                                    <FontAwesomeIcon icon="filter" size="2x" color="#406eb7"/>
                                </div>
                                <div className="column is-10" style={{textAlign:'left'}}>
                                    <h3>Optimization</h3>
                                    <p>Like most of our offerings, WesData's feed optimization service is customized to meet your needs.
                                        We begin with SKU-level sales and click data that is collected on a periodic basis. This data is
                                        analyzed using a proprietary method, and the results are used to rank your products based on
                                        their performance by partner. Final rankings are combined with flexible thresholds and
                                        custom rules that you define to determine which products make it into specific partner feeds.
                                        The end result drastically improves your ROAS.</p>
                                </div>
                            </div>
                            <div className="columns">
                                <div className="column is-2" style={{textAlign:'center'}}>
                                    <FontAwesomeIcon icon="balance-scale" size="2x" color="#406eb7"/>
                                </div>
                                <div className="column is-10" style={{textAlign:'left'}}>
                                    <h3>Weighting</h3>
                                    <p>At the core of our optimization service is an advanced weighting algorithm, which considers product
                                        aspects, age, conversion rates, value, and other factors, as well as arbitrary weighting that you can apply.
                                        Under-performers can be identified and excluded, saving ad-spend for items that perform.
                                        Perennial performers gain momentum that insures their ongoing inclusion.</p>
                                </div>
                            </div>
                            </div></div>
                        </div>
                        <div className="column is-3">
                        <div class="card">
                                <div class="card-content">
                                    <div class="media">
                                        <div class="media-content">
                                            <p class="title is-4">More Services</p>
                                        </div>
                                    </div>

                                    <div class="content">
                                        <ul>
                                            <li>
                                                <Link to="/services/shopping-feeds">Product Feeds</Link>
                                            </li>
                                            <li>
                                                <Link to="/services/partner-management">Partner Management</Link>
                                            </li>
                                            <li>
                                                <Link to="/services/roi-analysis">Feed Analytics</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      </Layout>
    )
  }
}
